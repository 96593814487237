import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import TagsInput from 'react-tagsinput'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import axios from 'axios';
import { AppContext } from '../context/appContext';
import 'react-tagsinput/react-tagsinput.css'
import { LoadScript } from '@react-google-maps/api';

const JoinUs = () => {

    const { apiUrl } = useContext(AppContext)
    const { token } = useParams();
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState('');
    const [errors, setErrors] = useState({});
    const [values, setValues] = useState({
        store_name: '',
        brand_name: '',
        address: '',
        coordinates: '',
        avg_prep_time: '',
        store_categories: [],
        food_categories: [],
        phone_number: '',
        email: '',
        image: '',
        bank: '',
        account_no: '',
        account_type: '',
        account_holder: '',
    });

    useEffect(() => {
        // Fetch store data if id is present
        const fetchData = async () => {
            if (!token) return; // If no token, don't fetch

            setLoading(true);
            try {
                const res = await axios.get(`${apiUrl}/get-application/${token}`); // Replace with your API endpoint
                setValues((preVal) => ({
                    ...preVal,
                    store_name: res.data.store_name,
                    brand_name: res.data.brand_name,
                    address: res.data.address,
                    coordinates: JSON.stringify(res.data.coordinates),
                    avg_prep_time: res.data.avg_prep_time,
                    store_categories: res.data.store_categories.split(','),
                    food_categories: res.data.food_categories.split(','),
                    phone_number: res.data.phone_number,
                    email: res.data.email,
                    bank: res.data.bank,
                    account_no: res.data.account_no,
                    account_type: res.data.account_type,
                    account_holder: res.data.account_holder,
                }))
                setLoading(false);
            } catch (error) {
                console.error('Error fetching store:', error);
                setAlert('Failed to fetch store details.');
                setLoading(false);
            }
        };

        fetchData();
    }, [token, apiUrl]);

    const handleChange = async (e) => {
        const { name, value, type, files } = e.target
        setErrors((prev)=> ({...prev, [name]: ''}))
        if (type === 'checkbox') {
            setErrors((prev)=> ({...prev, store_categories: ''}))
            setValues((prev) => {
                const updatedCategories = prev.store_categories.includes(name)
                  ? prev.store_categories.filter((category) => category !== name)
                  : [...prev.store_categories, name];
          
                return { ...prev, store_categories: updatedCategories };
            });
        } else if (type == 'file') {
            setValues((prev) => ({...prev, [name]: files[0] }))
            setErrors((prev)=> ({...prev, [name]: ''}))
        } else {
            setValues((prev)=> ({...prev, [name]: value}))
            setErrors((prev)=> ({...prev, [name]: ''}))
        }
    }

    const capitalizeWords = (input) => {
        return input.map((value) =>
            value
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
        );
    }

    const handleSelect = async (selectedAddress) => {
        try {
          const results = await geocodeByAddress(selectedAddress);
          const latLng = await getLatLng(results[0]);
          setValues((prev) => ({...prev, address: results[0].formatted_address, coordinates: JSON.stringify(latLng) }))
          setErrors((prev)=> ({...prev, address: ''}))
        } catch (error) {
          console.error('Error:', error);
        }
    };
    
    const handleSubmit = async () => {

        if (token) {
            editApplication()
            return
        };

        setLoading(true);
        const formData = new FormData();
        
        // Append all form values to formData
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
        });
    
        try {
            const res = await axios.post(`${apiUrl}/application`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setLoading(false);
            setAlert(res.data.message);
            setTimeout(() => {
                setAlert('');
            }, 5000);
        } catch (error) {
            console.log(error.response.data)
            setLoading(false);
            if (error.response && error.response.data) {
                setErrors(error.response.data || {});
                if (error.response.data.message) {
                    setAlert(error.response.data.message);
                }
            } else {
                setAlert('An error occurred. Please try again.');
            }
        }
    }; 

    const editApplication = async () => {
     
        setLoading(true);
        const formData = new FormData();
        
        // Append all form values to formData
        Object.keys(values).forEach(key => {
            formData.append(key, values[key]);
            // if (key === 'store_categories' || key === 'food_categories') {
            //     formData.append(key, JSON.stringify(values[key]));
            // } else {
            //    formData.append(key, values[key]);
            // }
        });
    
        try {
            const res = await axios.post(`${apiUrl}/edit-application/${token}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            });
            setLoading(false);
            setAlert(res.data.message);
            setTimeout(() => {
                setAlert('');
            }, 5000);
        } catch (error) {
            console.log(error.response.data)
            setLoading(false);
            if (error.response && error.response.data) {
                setErrors(error.response.data || {});
                if (error.response.data.message) {
                    setAlert(error.response.data.message);
                }
            } else {
                setAlert('An error occurred. Please try again.');
            }
        }
    }; 

    return (
        <>
            {/* hero-section */}
            <section className="hero-section about blog-page gap" style={{backgroundImage: "url(assets/img/blog-img-1.jpg)",}}>
                    <div className="container" >
                        <div className="row align-items-center" style={{height: '30rem'}}>
                            <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200" data-aos-duration="300">
                                    <div className="about-text">
                                        <ul className="crumbs d-flex">
                                            <li><Link to="/">Home</Link></li>
                                            <li className="two"><a href=""><i className="fa-solid fa-right-long"></i>Join Us</a></li>
                                        </ul>
                                        <h2>Join Our Partnership</h2>
                                        <p>Become a part of our growing network of merchants and couriers and enjoy the benefits of increased exposure, growth, and more.</p>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="join-courier">
                                    <h3>Join as a Merchant</h3>
                                    <p>Fill in the form below to apply and partner with us. We'll review your submission and get back to you soon.</p>
                                    <form className="blog-form" onSubmit={(e) => { e.preventDefault(); handleSubmit(); }} encType="multipart/form-data">
                                        <div className="name-form">
                                            <i className="fa fa-user" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="store_name" 
                                                placeholder="Enter your store name" 
                                                onChange={handleChange}
                                                value={values.store_name}
                                            />
                                            {errors.store_name && <span className="error-message">{errors.store_name}</span>}
                                        </div>
                                        <div className="name-form">
                                            <i className="fa fa-user" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="brand_name" 
                                                placeholder="Enter your brand name" 
                                                onChange={handleChange}
                                                value={values.brand_name}
                                            />
                                            {errors.brand_name && <span className="error-message">{errors.brand_name}</span>}
                                        </div>
                                        <div className="name-form">
                                            <i className="fa fa-location-dot" style={{color: '#adacac'}}></i>
                                            <LoadScript googleMapsApiKey={'AIzaSyCHoYD1s2a6oTDK3_5ozWadb4il3rve7pM&libraries=places'}>
                                                <PlacesAutocomplete
                                                    value={values.address}
                                                    onChange={(e) => {
                                                        setValues((prev) => ({...prev, address: e}));
                                                        setErrors((prev) => ({...prev, coordinates: '', address: ''}));
                                                    }}
                                                    onSelect={handleSelect}
                                                    searchOptions={{componentRestrictions: { country: ['ZA'] }}}
                                                >
                                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                        <div>
                                                            <input 
                                                                {...getInputProps({
                                                                    placeholder: 'Type address here...',
                                                                    className: 'location-search-input',
                                                                })}
                                                            />
                                                            <div className="autocomplete-dropdown-container">
                                                                {loading && <div>Loading...</div>}
                                                                {suggestions.map(suggestion => (
                                                                    <div {...getSuggestionItemProps(suggestion)} key={suggestion.placeId}>
                                                                        <span>{suggestion.description}</span>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    )}
                                                </PlacesAutocomplete>
                                            </LoadScript>
                                            {errors.address && <span className="error-message">{errors.address}</span>}
                                        </div>
                                        
                                        {/* Image Upload Field */}
                                        <div className="form-group mt-4">
                                            <label htmlFor="image">Store Logo Image</label>
                                            <input
                                                type="file"
                                                name="image"
                                                onChange={handleChange}
                                                accept="image/*"
                                                className="form-control-file"
                                            />
                                            {errors.image && <span className="error-message">{errors.image}</span>}
                                        </div>

                                        <div className="form-group mt-4">
                                            <label>Store Categories</label>
                                            <div className="checkbox-group">
                                                {[
                                                    { name: 'fastfood', label: 'Fast Food' },
                                                    { name: 'burger', label: 'Burger' },
                                                    { name: 'pizza', label: 'Pizza' },
                                                    { name: 'chicken', label: 'Chicken' },
                                                    { name: 'steak', label: 'Steak' },
                                                ].map((category, index) => (
                                                    <label key={index} className="checkbox-label">
                                                        <input
                                                            onChange={handleChange}
                                                            name={category.name}
                                                            type="checkbox"
                                                            checked={values.store_categories.includes(category.name)}
                                                        />
                                                        <span>{category.label}</span>
                                                    </label>
                                                ))}
                                            </div>
                                            {errors.store_categories && <span className="error-message">{errors.store_categories}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="tags">Food Categories</label>
                                            <TagsInput 
                                                value={values.food_categories} 
                                                onChange={(e) => {
                                                    setValues((prevValues) => ({ ...prevValues, food_categories: capitalizeWords(e) }));
                                                    setErrors((prev) => ({...prev, food_categories: ''}));
                                                }}
                                                onlyUnique
                                                inputProps={{ placeholder: 'Add here...' }}  
                                            />
                                            {errors.food_categories && <span className="error-message">{errors.food_categories}</span>}
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="avg_prep_time">Average Preparation Time</label>
                                            <select 
                                                onChange={handleChange} 
                                                value={values.avg_prep_time} 
                                                name='avg_prep_time' 
                                                className="form-select"
                                            >
                                                <option value="">Please choose average meal preparation time</option>
                                                <option value="0-15">0-15 minutes</option>
                                                <option value="15-30">15-30 minutes</option>
                                                <option value="30-45">30-45 minutes</option>
                                                <option value="45-60">45-60 minutes</option>
                                            </select>
                                            {errors.avg_prep_time && <span className="error-message">{errors.avg_prep_time}</span>}
                                        </div>

                                        <div className="name-form">
                                            <i className="fa fa-phone" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="phone_number" 
                                                placeholder="Enter your store phone number"
                                                onChange={handleChange}
                                                value={values.phone_number}
                                            />
                                            {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                        </div>

                                        <div className="name-form">
                                            <i className="fa fa-envelope" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="email" 
                                                name="email" 
                                                placeholder="Enter your store email"
                                                onChange={handleChange}
                                                value={values.email}
                                            />
                                            {errors.email && <span className="error-message">{errors.email}</span>}
                                        </div>

                                        <div className="name-form">
                                            <i className="fa fa-building-columns" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="bank" 
                                                placeholder="Enter your store bank name"
                                                onChange={handleChange}
                                                value={values.bank}
                                            />
                                            {errors.bank && <span className="error-message">{errors.bank}</span>}
                                        </div>

                                        <div className="name-form">
                                            <i className="fa fa-credit-card" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="account_no" 
                                                placeholder="Enter your store bank account number"
                                                onChange={handleChange}
                                                value={values.account_no}
                                            />
                                            {errors.account_no && <span className="error-message">{errors.account_no}</span>}
                                        </div>

                                        <div className="form-group mt-4">
                                            <label htmlFor="account_type">Account Type</label>
                                            <select 
                                                onChange={handleChange} 
                                                value={values.account_type} 
                                                name='account_type' 
                                                className="form-select"
                                            >
                                                <option value="" disabled>Select account type</option>
                                                <option value="savings">Savings Account</option>
                                                <option value="cheque">Cheque Account</option>
                                            </select>
                                            {errors.account_type && <span className="error-message">{errors.account_type}</span>}
                                        </div>

                                        <div className="name-form">
                                            <i className="fa fa-user" style={{color: '#adacac'}}></i>
                                            <input 
                                                type="text" 
                                                name="account_holder" 
                                                placeholder="Enter your store bank account holder"
                                                onChange={handleChange}
                                                value={values.account_holder}
                                            />
                                            {errors.account_holder && <span className="error-message">{errors.account_holder}</span>}
                                        </div>
                                        {/* Alert message */}
                                        {alert && (
                                            <div className="alert alert-success mt-4" role="alert">
                                                {alert}
                                            </div>
                                        )}
                                        <button type="submit" className="button-price mt-5" disabled={loading}>
                                            {loading ? 'Submitting...' : 'Submit Application'}
                                        </button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
            </section>
            {/* benefits-section */}
            <section className="Benefit-section gap" style={{ position: 'relative', zIndex: -1, height: 1100 }}>
            <div className="container">
                <div className="row">
                    <div className="offset-xl-1 col-lg-5" data-aos="flip-up" data-aos-delay="300" data-aos-duration="400">
                        <div className="benefit-text">
                            <h2>The Benefits of Partnering with Delivas</h2>
                            <p>As a restaurant or merchant, Delivas streamlines your operations by managing your orders, helping you reach more customers, and enhancing your delivery service without the hassle of logistics.</p>
                            <p className='mt-4'>For our courier partners, Delivas offers a flexible way to earn money by accepting delivery requests through the app. You decide when to work, making you your own boss. Get started today by downloading our app below.</p>
                            <a href="#" className="button button-2 mt-4">Download Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <style jsx>{`
            
                .store-form {
                    max-width: 500px;
                    margin: 2rem auto;
                    padding: 2rem;
                    background-color: #ffffff;
                    border-radius: 8px;
                    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                }

                h2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                    margin-bottom: 1.5rem;
                    color: #333;
                }

                .form-group {
                    margin-bottom: 1.5rem;
                }

                label {
                    display: block;
                    margin-bottom: 0.5rem;
                    font-weight: 500;
                    color: #4a5568;
                }

                input[type="text"],
                select,
                .tags-input {
                    width: 100%;
                    padding: 0.5rem;
                    border: 1px solid #e2e8f0;
                    border-radius: 4px;
                    font-size: 1rem;
                }

                input[type="text"]:focus,
                select:focus,
                .tags-input:focus {
                    outline: none;
                    border-color: #4299e1;
                    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
                }

                .checkbox-group {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.5rem;
                }

                .checkbox-label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                .checkbox-label input {
                    margin-right: 0.5rem;
                }

                .select-wrapper {
                    position: relative;
                }

                .select-arrow {
                    position: absolute;
                    right: 0.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                    pointer-events: none;
                }

                .tag {
                    background-color: #ebf8ff;
                    color: #2b6cb0;
                    border-radius: 9999px;
                    padding: 0.25rem 0.75rem;
                    margin-right: 0.5rem;
                    margin-bottom: 0.5rem;
                    display: inline-block;
                }

                .tag-remove {
                    margin-left: 0.5rem;
                    color: #4299e1;
                    cursor: pointer;
                }

                .tag-remove:hover {
                    color: #2b6cb0;
                }

                .submit-button {
                    width: 100%;
                    padding: 0.75rem;
                    background-color: #4299e1;
                    color: white;
                    border: none;
                    border-radius: 4px;
                    font-size: 1rem;
                    font-weight: 500;
                    cursor: pointer;
                    transition: background-color 0.2s ease;
                }

                .submit-button:hover {
                    background-color: #2b6cb0;
                }

                .autocomplete-dropdown-container {
                    position: absolute;
                    z-index: 10;
                    background-color: white;
                    border: 1px solid #e2e8f0;
                    border-top: none;
                    border-radius: 0 0 4px 4px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                    width: 100%;
                }

                .autocomplete-dropdown-container > div {
                    padding: 0.5rem;
                    cursor: pointer;
                }

                .autocomplete-dropdown-container > div:hover {
                    background-color: #f7fafc;
                }

                .error-message {
                    color: #dc3545;
                    font-size: 0.875rem;
                    margin-top: 0.25rem;
                    display: block;
                }

                .alert {
                    padding: 1rem;
                    margin-bottom: 1rem;
                    border-radius: 0.25rem;
                }

                .alert-success {
                    background-color: #d4edda;
                    border-color: #c3e6cb;
                    color: #155724;
                }

                .alert-danger {
                    background-color: #f8d7da;
                    border-color: #f5c6cb;
                    color: #721c24;
                }

                .form-control-file {
                    display: block;
                    width: 100%;
                    padding: 0.375rem 0.75rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    border: 1px solid #ced4da;
                    border-radius: 0.25rem;
                }

                button:disabled {
                    opacity: 0.65;
                    cursor: not-allowed;
                }
            `}</style>
        </>
    );
}

export default JoinUs;
